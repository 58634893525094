
export function getPaymentData (data = {}) {
  return apiFetch('/pays', { method: 'POST', body: data })
}

export function getPayment (paymentId) {
  return apiFetch(`/pays/${paymentId}`)
}

export function getPaymentHistory () {
  return apiFetch('/pays/history')
}

export function getPaymentCards () {
  return apiFetch('/pays/cards')
}

export function getPaymentAddCardUrl () {
  return apiFetch('/pays/cards', { method: 'POST' })
}

export function removePaymentCard (cardId) {
  return apiFetch(`/pays/cards/remove/${cardId}`, { method: 'DELETE' })
}

export function setGeneralCard (cardId) {
  return apiFetch(`/pays/cards/general/${cardId}`, { method: 'PATCH' })
}

export function calculatePayout (amount = null) {
  return apiFetch('/payouts/calc', { method: 'POST', body: { amount } })
}

export function createPayout (data) {
  return apiFetch('/payouts', { method: 'POST', body: data })
}
